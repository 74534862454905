<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Voucher</h1>
        <div class="flex items-center gap-6">
          <Button buttonText="Import Voucher" type="secondary" @action="toggleImportVoucher()" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Upload')" />
          <Button buttonText="Add Voucher" type="primary" @action="addData" />
        </div>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6">
          <div class="relative">
            <TextField type="text" label="Search by Voucher Code" :enterKeyAction="onSearch" withIcon borderEnabled v-model="options['code']" placeholder="Enter Voucher Code" />
            <div class="absolute left-3 top-11 transform -translate-y-1/2">
              <Search />
            </div>
          </div>
          <div class="relative">
            <TextField type="text" label="Search by Used Email" :enterKeyAction="onSearch" withIcon borderEnabled v-model="options['used_email']" placeholder="Enter Email" />
            <div class="absolute left-3 top-11 transform -translate-y-1/2">
              <Search />
            </div>
          </div>
          <Dropdown
            :options="mappingDigitalPlatforms"
            @change="onSearch"
            label="Filter by Digital Platforms"
            v-model="options['ecommerce']"
            :default="options['ecommerce']"
            placeholder="Select Digital Platforms"
          />
          <Dropdown :options="dummyOptions" optionLabel="label" label="Voucher Dummy" v-model="selectedDummy" :default="selectedDummy" placeholder="Select Status" />
        </div>
        <div class="mb-6 flex gap-4">
          <div class="grid grid-cols-2 gap-4 w-10/12">
            <Dropdown
              label="Filter by Class"
              :options="list_kelas"
              v-model="selectedCoreClass"
              :default="selectedCoreClass"
              placeholder="Select Class"
              optionLabel="nama"
              @search="getCoreClassList"
              maxHeight="300px"
              :isLoadData="isFetchingCoreClass"
              enableSearch
            />
            <Dropdown
              label="Filter by Schedule"
              :options="lmsClassList"
              v-model="selectedLmsClass"
              :default="selectedLmsClass"
              placeholder="Select Schedule"
              optionLabel="nama"
              @search="getLmsClassList"
              maxHeight="300px"
              :isLoadData="isFetchingLmsClass"
              enableSearch
            />
          </div>
          <div class="pt-5"><Button buttonText="Reset Filter" v-if="isResetVisible" additionalClass="py-3" type="secondary" @action="resetFilter" /></div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2">
              <Button buttonText="Edit" type="tertiary" size="full" @action="editMaster(props.row.id)" />
              <Button buttonText="Reset" type="delete" @action="resetVoucher(props.row.id)" />
            </div>
            <div v-else-if="props.column.field == 'customer_name'">
              <span style="display: block; white-space: nowrap">{{ props.formattedRow[props.column.field] }}</span>
            </div>
            <div v-else-if="props.column.field == 'core_class_name'" class="min-h-min">
              <div class="lms-class">
                <div class="text-yellow font-medium cursor-pointer" @click="openNewPage({ path: '/kelas', query: { name: props.formattedRow[props.column.field] } })">
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </div>
            </div>
            <div v-else-if="props.column.field == 'lms_class_name'" class="min-h-min">
              <div v-if="props.formattedRow[props.column.field]">
                <div class="lms-class">
                  <div class="text-yellow font-medium cursor-pointer" @click="openNewPage({ path: '/jadwalkelas', query: { name: props.formattedRow[props.column.field] } })">
                    {{ props.formattedRow[props.column.field] }}
                    ( {{ formatDate(props.row.lms_class_startAt).date }} <span v-if="props.row.lms_class_endAt">- {{ formatDate(props.row.lms_class_endAt).date }}</span> )
                  </div>
                </div>
              </div>
              <div v-else class="text-neutral-250">No Schedule Selected</div>
            </div>
            <div v-else-if="props.column.field === 'used_date'">
              <div v-if="props.formattedRow[props.column.field]">
                <span style="display: block; white-space: nowrap">
                  {{ formatDate(props.formattedRow[props.column.field]).date }}
                </span>
                <span style="display: block; font-size: 14px; color: #333">
                  {{ formatDate(props.formattedRow[props.column.field]).time }}
                </span>
              </div>
              <div v-else class="text-yellow">Available</div>
            </div>
            <div v-else-if="props.column.field === 'created_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <span v-else-if="props.column.field == 'url'">
              <img
                :src="
                  props.row.url +
                  (($route.meta.testingName == 'h') | ($route.meta.testingName == 'i') | ($route.meta.testingName == 'j') | ($route.meta.testingName == 'k') | ($route.meta.testingName == 'z')
                    ? '?onlyAnnotation=0'
                    : '')
                "
                class="img-fluid"
                alt="Image"
              />
            </span>
            <span v-else-if="props.column.field == 'dummy'" :class="[props.formattedRow[props.column.field] || [props.formattedRow[props.column.field]] !== false ? '' : 'text-neutral-250']">
              {{ props.formattedRow[props.column.field].toString() || 'Data Unavailable' }}
            </span>
            <span v-else :class="[props.formattedRow[props.column.field] ? '' : 'text-neutral-250']">
              {{ props.formattedRow[props.column.field] || 'Data Unavailable' }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div class="m-content">
        <div class="m-portlet m-portlet--mobile">
          <div class="m-portlet__body">
            <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
              <div class="row align-items-left">
                <div class="col-xl-12 order-2 order-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :modalVisible="visibleImportVoucher" @close="toggleImportVoucher()" :width="importVoucherModalWidth" id="upload-voucher">
      <template slot="modal-content">
        <VoucherImport
          :isScheduleCodeUnavailable="isScheduleCodeUnavailable"
          @handleCodeUnavailable="
            () => {
              isScheduleCodeUnavailable = true
            }
          "
          @close="closeImportVoucher"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Voucher',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    VoucherImport: () => import(/* webpackChunkName: "VoucherImport" */ '@/views/voucher/VoucherImport')
  },
  created() {
    this.DIGITAL_PLATFORMS()
    this.initData()
  },
  data() {
    return {
      searchTerm: null,
      options: {
        limit: 10,
        page: 0,
        ecommerce: '',
        dummy: '',
        core_class_id: '',
        lms_class_id: ''
      },
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      downloading: false,
      visibleImportVoucher: false,
      dummyOptions: [
        {
          status: true,
          label: 'Yes'
        },
        {
          status: false,
          label: 'No'
        }
      ],
      isFetchingCoreClass: false,
      isFetchingLmsClass: false,
      selectedCoreClass: null,
      selectedLmsClass: null,
      selectedDummy: null,
      lmsClassList: [],
      isScheduleCodeUnavailable: false
    }
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    },
    selectedCoreClass(value) {
      this.options.core_class_id = value.id
      this.selectedLmsClass = null
      this.onSearch()
    },
    selectedLmsClass(value) {
      this.options.lms_class_id = value.id
      this.onSearch()
    },
    selectedDummy(value) {
      this.options.dummy = value.status
      this.onSearch()
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'DIGITAL_PLATFORMS']),
    openNewPage(params) {
      const routeData = this.$router.resolve(params)
      window.open(routeData.href, '_blank')
    },
    openLink(link) {
      if (link === '-') return
      window.open(link, '_blank')
    },
    toggleImportVoucher() {
      if (this.visibleImportVoucher === false) {
        this.isScheduleCodeUnavailable = false
      }
      this.visibleImportVoucher = !this.visibleImportVoucher
    },
    closeImportVoucher() {
      this.isScheduleCodeUnavailable = false
      this.toggleImportVoucher()
      this.initData()
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    initData() {
      this.showLoading()
      this.stillLoading = true
      let paramsTemp = queryString.stringify({
        ...this.options
      })
      this.$store
        .dispatch('voucher/GET_LIST_VOUCHER', {
          customerId: this.clientId,
          params: paramsTemp
        })
        .then(() => {
          this.hideLoading()
          this.showProgress = false
          this.stillLoading = false
        })
    },
    resetFilter() {
      this.options = {
        limit: 10,
        page: 0,
        ecommerce: '',
        dummy: '',
        core_class_id: '',
        lms_class_id: ''
      }
      this.selectedCoreClass = null
      this.selectedLmsClass = null
      this.selectedDummy = null
      this.initData()
    },
    onPageChange(params) {
      this.showLoading()
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage - 1
      const paramsTemp = queryString.stringify({
        ...this.options
      })
      this.$store
        .dispatch('voucher/GET_LIST_VOUCHER', {
          customerId: this.clientId,
          params: paramsTemp
        })
        .then(() => {
          this.hideLoading()
        })
    },
    getCoreClassList(name = '') {
      this.isFetchingCoreClass = true
      const paramsTemp = queryString.stringify({
        ...{
          name,
          limit: 50
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          params: paramsTemp,
          customerId: this.clientId
        })
        .then((res) => {
          this.isFetchingCoreClass = false
        })
    },
    getLmsClassList(name = '') {
      this.isFetchingLmsClass = true
      const paramsTemp = queryString.stringify({
        ...{
          name,
          core_class_id: this.options?.core_class_id ? this.options?.core_class_id : '',
          limit: 50
        }
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          params: paramsTemp,
          customerId: this.clientId
        })
        .then((res) => {
          this.lmsClassList = res.data.data.map((lms) => {
            const nama = `${lms.nama} ( ${moment(lms.start_date).format('DD MMMM YYYY')} - ${moment(lms.end_date).format('DD MMMM YYYY')} )`
            return {
              ...lms,
              nama
            }
          })
          this.isFetchingLmsClass = false
        })
    },
    onSearch() {
      let paramsTemp = queryString.stringify({
        ...{
          limit: 10,
          page: 0
        },
        ...this.options
      })
      this.showLoading()
      this.$store
        .dispatch('voucher/GET_LIST_VOUCHER', {
          customerId: this.clientId,
          params: paramsTemp
        })
        .then(() => {
          this.hideLoading()
        })
    },
    editMaster(masterId) {
      this.$router.push('/voucher/edit/' + masterId)
    },
    deleteMaster(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('voucher/DELETE_VOUCHER', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.voucher)
                this.$store.dispatch('voucher/GET_LIST_VOUCHER', {
                  masterType: this.$route.meta.name,
                  customerId: this.clientId,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },
    downloadData() {
      this.downloading = true
      let paramsTemp = queryString.stringify({
        ...{
          name: null,
          sort: ''
        },
        ...this.options
      })
      this.$store
        .dispatch('voucher/DOWNLOAD_LIST_VOUCHER', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          testingName: this.$route.meta.testingName
        })
        .then((resp) => {
          this.downloading = false
        })
    },

    addData() {
      this.$router.push('/' + this.$route.meta.name + '/Add')
    },
    generateData() {
      this.$router.push('/' + this.$route.meta.name + '/generate')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn() {
      let result = 'default'
      return result
    },
    resetVoucher(masterId) {
      this.$dialog
        .confirm('Are you sure you want to reset?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('voucher/RESET_VOUCHER', {
              masterId: masterId,
              customerId: this.clientId
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.message)
              }
            })
        })
        .catch(function () {})
    }
  },
  computed: {
    ...mapGetters('common', ['digitalPlatforms']),
    ...mapGetters('kelas', ['list_kelas']),
    isResetVisible() {
      if (this.options.dummy === true || this.options.dummy === false) return true
      const copyData = JSON.parse(JSON.stringify(this.options))
      delete copyData.limit
      delete copyData.page
      return Object.values(copyData).filter((value) => value).length !== 0
    },
    importVoucherModalWidth() {
      return this.isScheduleCodeUnavailable ? '600px' : '900px'
    },
    data: function () {
      return this.$store.getters['voucher/list_voucher'] ? this.$store.getters['voucher/list_voucher'] : []
    },
    totalRecords: function () {
      return this.$store.getters['voucher/paginate'].total ? this.$store.getters['voucher/paginate'].total : 0
    },
    mappingDigitalPlatforms() {
      if (!this.digitalPlatforms) return []
      return this.digitalPlatforms.map((dp) => dp.code)
    },
    columns: function () {
      return [
        { field: 'code', label: 'Voucher Code' },
        { field: 'ecommerce', label: 'Ecommerce' },
        { field: 'core_class_name', label: 'Class' },
        { field: 'lms_class_name', label: 'Schedule' },
        { field: 'used_email', label: 'Used Email' },
        { field: 'discount_amount', label: 'Discount Amount' },
        { field: 'used_date', label: 'Used Date' },
        { field: 'schedule_code_pmo', label: 'Schedule Code' },
        { field: 'customer_name', label: 'Customer' },
        { field: 'dummy', label: 'Dummy' },
        { field: 'created_date', label: 'Created Date' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    },
    clientId() {
      return localStorage.getItem('client')
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}

.green {
  background: linear-gradient(#f4f5f8, #57c137);
}

.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.justify-start {
  justify-content: flex-start !important;
}

.lms-class {
  min-width: 300px;
}

.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
