var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen"
  }, [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Voucher")]), _c('div', {
    staticClass: "flex items-center gap-6"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Import Voucher",
      "type": "secondary",
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/Upload');
      }
    },
    on: {
      "action": function action($event) {
        return _vm.toggleImportVoucher();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Add Voucher",
      "type": "primary"
    },
    on: {
      "action": _vm.addData
    }
  })], 1)]), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6"
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Search by Voucher Code",
      "enterKeyAction": _vm.onSearch,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Enter Voucher Code"
    },
    model: {
      value: _vm.options['code'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'code', $$v);
      },
      expression: "options['code']"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-11 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Search by Used Email",
      "enterKeyAction": _vm.onSearch,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Enter Email"
    },
    model: {
      value: _vm.options['used_email'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'used_email', $$v);
      },
      expression: "options['used_email']"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-11 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1), _c('Dropdown', {
    attrs: {
      "options": _vm.mappingDigitalPlatforms,
      "label": "Filter by Digital Platforms",
      "default": _vm.options['ecommerce'],
      "placeholder": "Select Digital Platforms"
    },
    on: {
      "change": _vm.onSearch
    },
    model: {
      value: _vm.options['ecommerce'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'ecommerce', $$v);
      },
      expression: "options['ecommerce']"
    }
  }), _c('Dropdown', {
    attrs: {
      "options": _vm.dummyOptions,
      "optionLabel": "label",
      "label": "Voucher Dummy",
      "default": _vm.selectedDummy,
      "placeholder": "Select Status"
    },
    model: {
      value: _vm.selectedDummy,
      callback: function callback($$v) {
        _vm.selectedDummy = $$v;
      },
      expression: "selectedDummy"
    }
  })], 1), _c('div', {
    staticClass: "mb-6 flex gap-4"
  }, [_c('div', {
    staticClass: "grid grid-cols-2 gap-4 w-10/12"
  }, [_c('Dropdown', {
    attrs: {
      "label": "Filter by Class",
      "options": _vm.list_kelas,
      "default": _vm.selectedCoreClass,
      "placeholder": "Select Class",
      "optionLabel": "nama",
      "maxHeight": "300px",
      "isLoadData": _vm.isFetchingCoreClass,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getCoreClassList
    },
    model: {
      value: _vm.selectedCoreClass,
      callback: function callback($$v) {
        _vm.selectedCoreClass = $$v;
      },
      expression: "selectedCoreClass"
    }
  }), _c('Dropdown', {
    attrs: {
      "label": "Filter by Schedule",
      "options": _vm.lmsClassList,
      "default": _vm.selectedLmsClass,
      "placeholder": "Select Schedule",
      "optionLabel": "nama",
      "maxHeight": "300px",
      "isLoadData": _vm.isFetchingLmsClass,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getLmsClassList
    },
    model: {
      value: _vm.selectedLmsClass,
      callback: function callback($$v) {
        _vm.selectedLmsClass = $$v;
      },
      expression: "selectedLmsClass"
    }
  })], 1), _c('div', {
    staticClass: "pt-5"
  }, [_vm.isResetVisible ? _c('Button', {
    attrs: {
      "buttonText": "Reset Filter",
      "additionalClass": "py-3",
      "type": "secondary"
    },
    on: {
      "action": _vm.resetFilter
    }
  }) : _vm._e()], 1)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex flex-col gap-2"
        }, [_c('Button', {
          attrs: {
            "buttonText": "Edit",
            "type": "tertiary",
            "size": "full"
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        }), _c('Button', {
          attrs: {
            "buttonText": "Reset",
            "type": "delete"
          },
          on: {
            "action": function action($event) {
              return _vm.resetVoucher(props.row.id);
            }
          }
        })], 1) : props.column.field == 'customer_name' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(_vm._s(props.formattedRow[props.column.field]))])]) : props.column.field == 'core_class_name' ? _c('div', {
          staticClass: "min-h-min"
        }, [_c('div', {
          staticClass: "lms-class"
        }, [_c('div', {
          staticClass: "text-yellow font-medium cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.openNewPage({
                path: '/kelas',
                query: {
                  name: props.formattedRow[props.column.field]
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])])]) : props.column.field == 'lms_class_name' ? _c('div', {
          staticClass: "min-h-min"
        }, [props.formattedRow[props.column.field] ? _c('div', [_c('div', {
          staticClass: "lms-class"
        }, [_c('div', {
          staticClass: "text-yellow font-medium cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.openNewPage({
                path: '/jadwalkelas',
                query: {
                  name: props.formattedRow[props.column.field]
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ( " + _vm._s(_vm.formatDate(props.row.lms_class_startAt).date) + " "), props.row.lms_class_endAt ? _c('span', [_vm._v("- " + _vm._s(_vm.formatDate(props.row.lms_class_endAt).date))]) : _vm._e(), _vm._v(" ) ")])])]) : _c('div', {
          staticClass: "text-neutral-250"
        }, [_vm._v("No Schedule Selected")])]) : props.column.field === 'used_date' ? _c('div', [props.formattedRow[props.column.field] ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : _c('div', {
          staticClass: "text-yellow"
        }, [_vm._v("Available")])]) : props.column.field === 'created_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field == 'url' ? _c('span', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": props.row.url + (_vm.$route.meta.testingName == 'h' | _vm.$route.meta.testingName == 'i' | _vm.$route.meta.testingName == 'j' | _vm.$route.meta.testingName == 'k' | _vm.$route.meta.testingName == 'z' ? '?onlyAnnotation=0' : ''),
            "alt": "Image"
          }
        })]) : props.column.field == 'dummy' ? _c('span', {
          class: [props.formattedRow[props.column.field] || [props.formattedRow[props.column.field]] !== false ? '' : 'text-neutral-250']
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field].toString() || 'Data Unavailable') + " ")]) : _c('span', {
          class: [props.formattedRow[props.column.field] ? '' : 'text-neutral-250']
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field] || 'Data Unavailable') + " ")])];
      }
    }])
  })], 1), _vm._m(0)]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleImportVoucher,
      "width": _vm.importVoucherModalWidth,
      "id": "upload-voucher"
    },
    on: {
      "close": function close($event) {
        return _vm.toggleImportVoucher();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('VoucherImport', {
    attrs: {
      "isScheduleCodeUnavailable": _vm.isScheduleCodeUnavailable
    },
    on: {
      "handleCodeUnavailable": function handleCodeUnavailable() {
        _vm.isScheduleCodeUnavailable = true;
      },
      "close": _vm.closeImportVoucher
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-content"
  }, [_c('div', {
    staticClass: "m-portlet m-portlet--mobile"
  }, [_c('div', {
    staticClass: "m-portlet__body"
  }, [_c('div', {
    staticClass: "m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
  }, [_c('div', {
    staticClass: "row align-items-left"
  }, [_c('div', {
    staticClass: "col-xl-12 order-2 order-xl-1"
  })])])])])]);
}]

export { render, staticRenderFns }